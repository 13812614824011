<template>
  <div class="ma-4">
    <v-toolbar class="mb-2">
      <v-toolbar-title>Schedule Change Details: {{ name }} - {{ bannerId }}</v-toolbar-title>
      <v-spacer></v-spacer>
      <v-btn to="/student/schedule/admin" text>Back to List</v-btn>
    </v-toolbar>
    <v-row>
      <v-col :cols="12" md="6">
        <v-expansion-panels v-model="panel">
          <template v-for="{ label, changes } in sections">
            <v-expansion-panel v-if="changes.length > 0" :key="'panel-' + label">
              <v-expansion-panel-header>{{ label }}</v-expansion-panel-header>
              <v-expansion-panel-content :style="'background-color:#' + (isDark ? '333333' : 'eeeeee')">
                <change v-for="{ _id, action, status, course, crn, gradeMode, approvals } in changes" :key="'pending-' + _id" :main-id="id" :change-id="_id" :action="action" :status="status" :course="course" :crn="crn" :approvals="approvals" :advisors="advisors" :grade-mode="gradeMode"></change>
                <v-btn v-if="label === 'Initial Review'" color="success" class="mt-4" @click="sendToApprovers">
                  <v-icon left>fal fa-check-circle</v-icon>
                  Send To Approvers
                </v-btn>
              </v-expansion-panel-content>
            </v-expansion-panel>
          </template>
        </v-expansion-panels>
      </v-col>
      <v-col :cols="12" md="6">
        <v-card :style="'background-color:#' + (isDark ? '333333' : 'eeeeee')">
          <v-toolbar dense>
            <v-toolbar-title>Timeline</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-dialog v-model="addCommentDialog" width="500">
              <template v-slot:activator="{ on }">
                <v-btn v-on="on" icon>
                  <v-icon>fal fa-comment-plus</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Add Comment</v-card-title>
                <v-card-text>
                  <p>Add a comment/message. This can be visible to the student, or can be made only visible to other instructors, advisors, and the Records office. No notification is sent to the student unless it is visible to the student and the box is checked.</p>
                  <v-textarea v-model="comment" label="Comment/Message" outlined></v-textarea>
                  <p>Should this comment be visible to or hidden from the student?</p>
                  <v-switch v-model="visibleToStudent" :label="(visibleToStudent ? 'Visible to' : 'Hidden from') + ' the student'" hide-details></v-switch>
                  <v-checkbox v-if="visibleToStudent" v-model="notifyStudent" label="Send a notification to the student with this message" hide-details></v-checkbox>
                </v-card-text>
                <v-card-actions>
                  <v-btn text @click="addCommentDialog = false">Cancel</v-btn>
                  <v-btn :disabled="comment === ''" color="success" text @click="addComment">Add Comment</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-toolbar>
          <v-card-text>
            <v-timeline dense>
              <v-timeline-item v-for="({ _id, color, icon, pidm, name, date, text, expanded }, index) in timeline" :key="'message-' + _id" :color="color || 'info'" :class="!(icon) && pidm !== user.pidm ? 'reverse-icon' : ''">
                <template v-slot:icon>
                  <v-icon color="white" small>{{ icon || 'fal fa-comment'}}</v-icon>
                </template>
                <v-card @click="timeline[index].expanded = !expanded" style="cursor:pointer">
                  <v-card-text :style="expanded ? 'padding-bottom:10px;overflow:auto' : 'overflow:hidden;text-overflow:ellipsis;white-space:nowrap;padding-bottom:10px;'" v-html="text"></v-card-text>
                  <v-card-text style="font-size:.8em;opacity:.7;padding-top:0">{{ name }} -- {{ stringFormatDate(date) }}</v-card-text>
                </v-card>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<style>
.v-timeline-item .v-timeline-item__inner-dot i.v-icon {
  font-size: 18px;
}
.v-timeline-item.reverse-icon .v-timeline-item__inner-dot i.v-icon {
  -webkit-transform: scaleX(-1);
  transform: scaleX(-1);
}
</style>
<script>
import { ref, computed, watch, onBeforeMount, onBeforeUnmount } from '@vue/composition-api'
import { stringFormatDate } from '../../../helpers/formatters'
// import { buildCourseBlock, addToCalendar } from '@/components/student/schedule-change/functions'

export default {
  components: {
    change: () => import('@/components/student/schedule/admin/change')
  },
  setup (props, { root }) {
    const service = root.$feathers.service('student/schedule-change')
    const user = computed(() => root.$store.state.user.spoof || root.$store.state.user)
    const roles = computed(() => user.value.roles)
    const isDark = computed(() => 'settings' in user.value ? user.value.settings.darkMode : false)
    const id = computed(() => root.$route.params.id)
    const status = ref('')
    const panel = ref(0)

    const detailUpdatedAt = ref('2000-01-01')
    const advisors = ref([])
    const term = ref('')
    const pidm = ref('')
    const bannerId = ref('')
    const name = ref('')
    const changes = ref([])
    const sections = ref([
      { label: 'Initial Review', code: 'submitted', changes: [] },
      { label: 'Pending Approval', code: 'pending', changes: [] },
      { label: 'Pending Processing', code: 'approved', changes: [] },
      { label: 'Returned to Student', code: 'returned', changes: [] },
      { label: 'Completed', code: 'completed', changes: [] },
      { label: 'Cancelled by Student', code: 'cancelled', changes: [] }
    ])
    const timeline = ref([])

    async function load () {
      try {
        const aggregate = [
          { $match: { _id: id.value } },
          { $addFields: {
            completed: {
              $map: {
                input: '$completed',
                as: 'el',
                in: {
                  $cond: [{ $eq: ['$$el.cancelled', true] }, { $mergeObjects: ['$$el', { status: 'cancelled' }] }, { $mergeObjects: ['$$el', { status: 'completed' }] }]
                }
              }
            }
          } },
          { $project: {
            term: 1,
            pidm: 1,
            bannerId: 1,
            name: 1,
            timeline: 1,
            changes: { $concatArrays: [{ $ifNull: ['$changes', []] }, { $ifNull: ['$completed', []] }] }
          } },
          { $unwind: '$changes' },
          { $lookup: {
            from: 'Calendar-Classes',
            localField: 'changes.crn',
            foreignField: 'crn',
            as: 'course',
            let: { term: '$term' },
            pipeline: [
              { $match: { $expr: { $eq: ['$term', '$$term'] } } },
              { $project: {
                title: 1,
                instructor: { $first: '$instructors' },
                meetingBase: 1
              } },
              { $project: {
                title: 1,
                instructor: '$instructor.name',
                meets: {
                  $reduce: {
                    input: '$meetingBase',
                    initialValue: '',
                    in: {
                      $concat: [
                        '$$value',
                        { $cond: [{ $eq: ['$$value', ''] }, '', '<br/>'] },
                        { $cond: [{ $eq: ['$$this.days', ''] }, '', { $concat: ['$$this.days', ' '] }] },
                        { $cond: [{ $eq: ['$$this.startTime', ''] }, '', { $concat: ['$$this.startTime', ' - '] }] },
                        '$$this.endTime',
                        { $cond: [
                          { $eq: ['$$this.room', null] },
                          '',
                          { $concat: [' in ', '$$this.room', ' (', '$$this.building', ')'] }
                        ] }
                      ]
                    }
                  }
                }
              } }
            ]
          } },
          { $project: {
            term: 1,
            pidm: 1,
            bannerId: 1,
            name: 1,
            timeline: 1,
            change: '$changes',
            course: { $first: '$course' } }
          },
          { $lookup: {
            from: 'Directory',
            localField: 'change.approvals.pidm',
            foreignField: 'pidm',
            as: 'approvers',
            pipeline: [
              { $project: { pidm: 1, name: { $concat: ['$name.last', ', ', '$name.first'] }, email: 1 } }
            ]
          } }
        ]
        const { data } = await service.find({ query: { aggregate } })
        if (data.length === 0) {
          changes.value = []
          timeline.value = []
        } else {
          // Look up the names for the instructors and advisors and add them to the approvals within the changes
          // const pidms = []
          // for (let i = 0; i < data.length; i++) {
          //   const { status, change: { approvals } } = data[i]
          //   approvals.forEach(({ pidm }) => { if (!(pidms.includes(pidm))) pidms.push(pidm) })
          //   if (status == null) data[i].status = 'completed'
          // }
          // const { data: apprData } = await root.$feathers.service('directory/people').find({ query: { pidm: { $in: pidms }, $limit: pidms.length, $select: ['pidm', 'name'] } })
          // const hash = {}
          // apprData.forEach(({ pidm, name: { first, last } }) => {
          //   hash[pidm] = last + ', ' + first
          // })

          term.value = data[0].term
          pidm.value = data[0].pidm
          bannerId.value = data[0].bannerId
          name.value = data[0].name
          changes.value = data.map(({ change, course, approvers }) => {
            const temp = { ...change }
            temp.approvals = temp.approvals.map((row) => {
              if ('pidm' in row) {
                const rec = approvers.find(({ pidm }) => pidm === row.pidm)
                return { ...row, name: rec.name || '**Not Found**' }
              } else {
                return row
              }
            })
            return { ...temp, course }
          })
          for (let i = 0; i < sections.value.length; i++) {
            sections.value.splice(i, 1, { ...sections.value[i], changes: changes.value.filter(({ status }) => status === sections.value[i].code) })
          }
          timeline.value = data[0].timeline.map((row) => { return { ...row, expanded: row.text.length < 100 } }).reverse()
        }
        const { data: termData } = await root.$feathers.service('student/term-detail').find({ query: { term: term.value, pidm: pidm.value } })
        if (termData.length > 0) {
          detailUpdatedAt.value = termData[0].updatedAt
          advisors.value = termData[0].academics.advisor.sort(({ code: aCode, name: aName }, { code: bCode, name: bName }) => {
            if (aCode === bCode) return aName < bName ? -1 : 1
            else if (aCode === 'MAJR') return -1
            else if (bCode === 'MAJR') return 1
            else if (aCode === 'COR1') return -1
            else if (bCode === 'COR1') return 1
            else if (aCode === 'EDU') return -1
            else if (bCode === 'EDU') return 1
            else if (aCode === 'CERT') return -1
            else if (bCode === 'CERT') return 1
            else return aCode < bCode ? -1 : 1
          })
        }
      } catch (e) {
        root.$store.dispatch('main/snackbar', { color: 'error', text: 'Error loading schedule change: ' + e })
        root.$router.replace('/student/schedule/admin')
      }
    }
    watch(id, () => {
      load()
    })

    async function sendToApprovers () {
      const reviewChanges = changes.value.filter(({ status }) => status === 'submitted')
      // Email the approvers
      const pidms = []
      const courses = []
      reviewChanges.forEach(({ approvals, course: { title } }) => {
        courses.push(title)
        approvals.forEach(({ pidm }) => {
          if (!pidms.includes(pidm)) pidms.push(pidm)
        })
      })
      const { data } = await root.$feathers.service('directory/people').find({ query: { pidm: { $in: pidms } } })
      const to = data.map(({ email }) => email)
      await root.$feathers.service('system/email').create({ to, subject: 'Schedule Change for ' + name.value + ' is Pending your Approval', html: 'A schedule change has been submitted by ' + name.value + ' (' + bannerId.value + ')' + ' and is pending your approval. Please <a href="https://portal.covenant.edu/student/schedule/admin">Log into the Portal</a> to review this request. You can either approve or acknowledge the change, or you can return the request to the student and send them a message as to why.<br/><br/>Thank you for your quick response to this matter.<br/><br/>Records Office' })
      // Update the status of this change to pending approval
      let obj = {
        pidm: user.value.pidm,
        name: user.value.name,
        date: new Date(),
        text: 'Initial Records Review Complete for Course Change' + (courses.length > 1 ? 's' : '') + ':<br/>' + courses.join('<br/>'),
        icon: 'fal fa-check',
        color: 'success',
        visibleToStudent: true
      }
      const patch = {
        $push: { timeline: obj },
        'changes.$[chg].status': 'pending'
      }
      const arrayFilters = [{ 'chg.status': 'submitted' }]
      await service.patch(id.value, patch, { query: { arrayFilters } })
    }

    const addCommentDialog = ref(false)
    const comment = ref('')
    const visibleToStudent = ref(true)
    const notifyStudent = ref(false)
    async function addComment () {
      if (notifyStudent.value) {
        // Get the student's email address
        const { data } = await root.$feathers.service('directory/people').find({ query: { pidm: pidm.value } })
        if (data.length > 0) {
          await root.$feathers.service('system/email').create({ to: data[0].email, from: 'report-email@covenant.edu', subject: 'Schedule Change: Comment Added', html: user.value.name + ' just added a comment to your schedule change request. To view the comment and see the status of your request, please <a href="https://portal.covenant.edu/student/schedule">log into the Portal</a>.' })
        }
      }
      let obj = {
        pidm: user.value.pidm,
        name: user.value.name,
        date: new Date(),
        text: comment.value,
        icon: 'fal fa-comment',
        color: 'info',
        visibleToStudent: visibleToStudent.value
      }
      await service.patch(id.value, { $push: { timeline: obj } })
      addCommentDialog.value = false
      comment.value = ''
    }

    function onListener (row) {
      console.log(row)
      if (row._id === id.value) {
        load()
      }
    }

    onBeforeMount(() => {
      service.on('patched', onListener)
    })

    onBeforeUnmount(() => {
      service.removeListener('patched', onListener)
    })

    return {
      user,
      roles,
      isDark,
      id,
      status,
      panel,
      detailUpdatedAt,
      advisors,
      term,
      pidm,
      bannerId,
      name,
      changes,
      sections,
      timeline,
      sendToApprovers,
      addCommentDialog,
      comment,
      visibleToStudent,
      notifyStudent,
      addComment,
      stringFormatDate
    }
  }
}
</script>
